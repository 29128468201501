<template>
  <div>
    <div class="collection-cards-network" :class="getBadgeNetwork(data)">
      <b-col class="text-white d-flex flex-column justify-content-center pl-3 col-5">
        <div>
          <b-badge class="badge-collection">{{ $t('creator.collection') }}</b-badge>
        </div>
        <h2 class="text-white mt-1">{{nameNetworks(data)}}</h2>
        <div>
          <b-button variant="light" class="mt-2" @click="search">{{ $t('creator.viewAll') }}</b-button>
        </div>
      </b-col>
      <b-col class="col-7 position-relative">
        <div class="images-inner-first">
          <b-img :src="img" class="img-collections"></b-img>
          <b-img :src="img" class="img-collections opacity-25"></b-img>
        </div>
        <div class="images-inner-secondary">
          <b-img :src="img" class="img-collections opacity-25"></b-img>
          <b-img :src="img" class="img-collections opacity-25"></b-img>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCol,
  BImg,
} from 'bootstrap-vue';
import { nameNetworks } from '@/libs/utils/formats'
import { getBadgeNetwork} from '@/libs/utils/icons';

export default {
  name: 'collectionCardNetwork',
  components: {
    BBadge,
    BButton,
    BCol,
    BImg,
  },
  props: {
    data: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
    },
    img: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      nameNetworks,
      getBadgeNetwork
    }
  },

  methods: {
    search() {
      this.$emit('reload_service', {networks: [this.data]});
    },
  }
}
</script>

<style scoped>
.collection-cards-network {
  height: 280px;
  border-radius: 1.5em;
  margin: 0 2em 0 0;
  display: flex;
  overflow: hidden;
}
.badge-collection {
  background-color: rgba(249,250,252,.2) !important;
}
.img-collections {
  width: 170px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 1em;
  border-radius: 0.7em;
}
.images-inner-first {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 2em;
}
.images-inner-secondary {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -2em;
  right: 0;
}
.opacity-25 {
  opacity: 0.25;
}
</style>